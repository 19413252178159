"use client";
import React from "react";
import { cn } from "src/lib/utils";
import { BoxesCore } from "../components/ui/background-boxes";
import { ButtonsCard } from "../components/ui/tailwindcss-buttons";
import { BackgroundGradientAnimation } from "../components/ui/background-gradient-animation";
import { MacbookScroll } from "../components/ui/macbook-scroll";
import { Link } from "react-router-dom";
import Button from "../components/socialbutton2";

export function Pruebas() {
  return (
    <Button/>
  );
}
