import { Tabs } from '../components/ui/tabs';
import { HoverEffect } from '../components/ui/card-hover-effect';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { WobbleCard } from '../components/ui/wobble-card';
import budget from '../assets/images/budget.svg'
import linearbudget from '../assets/images/linearbudget.svg'
import ipad from "../assets/images/ipad-min.png";

const PlanningPage = () => {
  const navigate = useNavigate();
  const tabs = [
    {
      title: 'Finance',
      value: 'finance',
      content: (
        <div className="w-full overflow-hidden relative h-full rounded-2xl p-10 text-xl md:text-4xl font-bold text-white bg-gradient-to-br from-custom-blue to-custom-blue">
          <p>Product Tab</p>
          <DummyContent />
        </div>
      ),
    },
    {
      title: 'Sales',
      value: 'sales',
      content: (
        <div className="w-full overflow-hidden relative h-full rounded-2xl p-10 text-xl md:text-4xl font-bold text-white bg-gradient-to-br from-custom-blue to-custom-blue">
          <p>Services tab</p>
          <DummyContent />
        </div>
      ),
    },
    {
      title: 'Marketing',
      value: 'marketing',
      content: (
        <div className="w-full overflow-hidden relative h-full rounded-2xl p-10 text-xl md:text-4xl font-bold text-white bg-gradient-to-br from-custom-blue to-custom-blue">
          <p>Playground tab</p>
          <DummyContent />
        </div>
      ),
    },
  ];
  return (
    <div className="container mx-auto p-5">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
      {/* Sección superior con el título y el botón */}
      <div className="text-left mb-1" style={{ paddingBottom: '130px' }}>
        <h1 className="text-4xl font-bold text-custom-blue">
          Business planning with Strathens.
        </h1>
        <p className="text-left text-[1.25rem] leading-6 py-7 text-gray-600">
          Automate and centralize your departments to plan in one place
        </p>
        <button className="px-6 py-2 bg-custom-blue text-white rounded-lg font-bold transform hover:-translate-y-1 transition duration-400" onClick={() => navigate("/demo")}>
                      Request a Demo
                    </button> 
      </div>
      <div className="flex justify-center mb-12">
          <img
            src={ipad}
            alt="ipad"
            className="w-full md:w-4/5 lg:w-3/4 xl:w-2/3 object-contain"
          />
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 max-w-7xl mx-auto w-full">
        <WobbleCard
        containerClassName="col-span-1 lg:col-span-2 h-full bg-custom-blue min-h-[500px] lg:min-h-[300px]"
        className=""
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
        <div className="max-w-xs">
          <h2 className="text-left text-balance text-base md:text-xl lg:text-3xl font-semibold tracking-[-0.015em] text-white">
            Evolving towards digital planning
          </h2>
          <h3 className="text-2xl font-bold mb-4 text-white py-4">
              Eliminate human errors
            </h3>
          <ul className="list-disc list-inside text-lg text-white">
              <li>
                Discover a multi-directional level of planning for your entire company
              </li>
              <li>
                Increase the accuracy of your forecast thanks to artificial intelligence for unique precision
              </li>
              <li>
                Create possible scenarios and anticipate mitigating risks
              </li>
            </ul>
        </div>
        <div className="flex justify-center mb-12">
          <img
            src={linearbudget}
            alt="linearbudget"
            style={{ width: '30rem', height: '30rem' }}
          />
        </div>
        </div>
        
      </WobbleCard>
      </div>

      {/* Nueva sección: Presupuestos que impulsen tu crecimiento */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12 items-center py-12" style={{ paddingTop: '5rem' }}>
  {/* Left Column: Image */}
  <div className="flex justify-center md:justify-end">
    <img src={budget} alt="grafica" className='pl-1' style={{ width: '30rem', height: '30rem' }} />
  </div>

  {/* Right Column: Text */}
  <div>
    <h3 className="text-2xl font-bold text-custom-blue mb-4">
      Budgets that drive your growth.
    </h3>
    <ul className="list-disc list-inside text-lg text-gray-600">
      <li>Financial planning with precision, forget about unexpected costs</li>
      <li>Create variable budgets, anticipate your income streams and analyze in detail the financial needs of each department.</li>
    </ul>
  </div>
</div>

      {/* Nueva sección: Análisis y planificación */}
      <div className="text-center mb-12">
        <h2 className="text-3xl font-semibold text-custom-blue">
          Analyze your current situation and plan to direct your company until you achieve your objectives.
        </h2>
        <p className="text-center text-[1.25rem] leading-6 mx-auto py-7 text-gray-600">
          From your financial planning, along with your sales forecasts and marketing returns.
        </p>
      </div>

      {/* Tab Section */}
      <div className="h-[20rem] md:h-[40rem] [perspective:1000px] relative b flex flex-col max-w-5xl mx-auto w-full  items-start justify-start my-10">
        <Tabs tabs={tabs} />
      </div>

      {/* Nueva sección: ¿Por qué Stratthens para la planificación empresarial? */}
      <div className="text-center mb-12 mt-20 pt-10">
        <h2 className="text-3xl font-semibold text-custom-blue mb-8 mt-20">
          Why Strathens for business planning?
        </h2>

        <div className="max-w-5xl mx-auto px-8 col-auto">
          <HoverEffect items={projects} />
        </div>

        <button className="px-6 py-2 bg-custom-blue text-white rounded-lg font-bold transform hover:-translate-y-1 transition duration-400" onClick={() => navigate("/demo")}>
                      Request a Demo
                    </button>
      </div>
    </div>
  );
};

export default PlanningPage;

export const projects = [
  {
    title: 'Planning for any scenario',
    description: '',
  },
  {
    title: 'Eliminate blind spots in your operations',
    description: '',
  },
  {
    title: 'Effective collaboration between departments',
    description: '',
  },
  {
    title: 'Designed to achieve your goals',
    description: '',
  },
];
const DummyContent = () => {
  return (
    <img
      src="/linear.webp"
      alt="dummy image"
      width="1000"
      height="1000"
      className="object-cover object-left-top h-[60%]  md:h-[90%] absolute -bottom-10 inset-x-0 w-[90%] rounded-xl mx-auto"
    />
  );
};
