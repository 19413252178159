import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './index.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import RootLayout from './layouts/root-layout';
import IndexPage from './routes/indexpage';
import AnalisyPage from './routes/analisypage';
import PlanningPage from './routes/planning';
import ReportPage from './routes/reportingpage';
import EnterprisePage from './routes/enterprisepage';
import ContactPage from './routes/contactpage';
import DemoRequest from './routes/demopage';
import { Pruebas } from './routes/pruebas';
import Platform from './routes/platform';
import PrivacyPolicy from './components/PrivacyPolicy';

const router = createBrowserRouter([
  {
    element: <RootLayout />,
    children: [
      {
        element: <IndexPage />,
        path: '/',
      },
      {
        path: '/planning',
        element: <PlanningPage />,
      },
      {
        path: '/analisis',
        element: <AnalisyPage />,
      },
      {
        path: '/reporting',
        element: <ReportPage />,
      },
      {
        path: '/aboutus',
        element: <EnterprisePage />,
      },
      {
        path: '/contact',
        element: <ContactPage />,
      },
      {
        path: '/demo',
        element: <DemoRequest />,
      },
      {
        path: '/pruebas',
        element: <Pruebas />,
      },
      {
        path: '/Platform',
        element: <Platform/>,
      },
      {
        path: '/privacy-policy',
        element: <PrivacyPolicy/>,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
