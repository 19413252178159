import React from 'react'

const Card = ({ className, children, ...props }) => {
  return (
    <div className={`bg-white rounded-lg border-gray-200 ${className}`} {...props}>
      {children}
    </div>
  )
}

const CardHeader = ({ className, children, ...props }) => {
  return (
    <div className={`p-6 ${className}`} {...props}>
      {children}
    </div>
  )
}

const CardContent = ({ className, children, ...props }) => {
  return (
    <div className={`p-6 pt-0 ${className}`} {...props}>
      {children}
    </div>
  )
}

const CardFooter = ({ className, children, ...props }) => {
  return (
    <div className={`p-6 pt-0 ${className}`} {...props}>
      {children}
    </div>
  )
}

const CardTitle = ({ className, children, ...props }) => {
  return (
    <h3 className={`text-2xl font-semibold text-custom-blue leading-none tracking-tight ${className}`} {...props}>
      {children}
    </h3>
  )
}

const CardDescription = ({ className, children, ...props }) => {
  return (
    <p className={`text-2xl text-gray-500 dark:text-gray-400 ${className}`} {...props}>
      {children}
    </p>
  )
}

export { Card, CardHeader, CardContent, CardFooter, CardTitle, CardDescription }