import React from "react";
import { useNavigate } from "react-router-dom";
import ipad from "../assets/images/ipad-min.png";
import { HoverEffect } from "../components/ui/card-hover-effect";
const ReportPage = () => {
  const navigate = useNavigate();
  return (
    <div className="container mx-auto p-5">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
        <div className="text-left">
          <h1 className="text-4xl font-bold text-custom-blue">
            Crear reports y compartir informes nunca fue tan fácil.
          </h1>
          <p className="text-left text-[1.25rem] leading-6 py-7 text-gray-600">
            Crea, exporta y comparte informes integrando toda la información de
            que necesites en una misma fuente de certeza
          </p>
          <button className="px-6 py-2 bg-custom-blue text-white rounded-lg font-bold transform hover:-translate-y-1 transition duration-400" onClick={() => navigate("/demo")}>
                      Request a Demo
                    </button>
        </div>
        <div className="flex justify-center mb-12">
          <img
            src={ipad}
            alt="ipad"
            className="w-full md:w-4/5 lg:w-3/4 xl:w-2/3 object-contain"
          />
        </div>
      </div>
      <div className="text-center mb-12">
        <h2 className="text-3xl font-semibold text-custom-blue">
          Una plataforma para comunicar lo más relavante de la mejor manera posible.
        </h2>
      </div>
      {/* New Section */}
      <div className="text-center mt-20">
        <h2 className="text-3xl font-semibold text-custom-blue">
          Diseña reports visuales y compártelos con tus directivos o cualquier
          stakeholder de tu organización.
        </h2>
      </div>

      <div className="mt-10 flex justify-between items-start">
        <div className="flex-1 text-center">
          <p className="text-lg text-gray-800">CREATIVO</p>
        </div>
        <div className="flex-1 space-y-4">
          <div className="bg-custom-blue text-white p-4 rounded-md shadow-md">
            Personalizo para añadir contexto a los lectores
          </div>
          <div className="bg-custom-blue text-white p-4 rounded-md shadow-md">
            Incorpora dashboards interactivos y personalizables
          </div>
          <div className="bg-custom-blue text-white p-4 rounded-md shadow-md">
            Comparte conclusiones y resultados analíticos
          </div>
        </div>
      </div>
      {/* New Section */}
      <h2 className="text-2xl text-custom-blue mb-10 text-center" style={{paddingTop: '5rem'}}>
          ¿Por qué Strathens para el reporting de tu organización?
        </h2>
      <div className="max-w-5xl mx-auto px-8">
        <HoverEffect items={projects} />
      </div>
    </div>
  );
};

export default ReportPage;

export const projects = [
  {
    title: 'Personalización para cada stakeholder',
    description: '',
  },
  {
    title: 'Crea informes con la información en un mismo lugar',
    description: '',
  },
  {
    title:
      'Mayor comunicación, mejores resultados',
    description: '',
  },
];
