import React from 'react'
import { Check } from "lucide-react"

export default function DemoRequest() {
  return (
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
        <div className="space-y-6">
          <h1 className="text-4xl font-bold text-custom-blue" style={{paddingBottom: '2rem'}}>
            Request a personalized demo for your company
          </h1>
          <p className="text-gray-600"style={{paddingBottom: '1rem'}}>
            Discover Strathens and the potential it could have in your organization.
          </p>

          <div className="space-y-6" style={{marginRight: '1rem'}}>
            {[
              {
                title: "Response from our team",
                description: "One of our experts will contact you within 24 hours to schedule your demo."
              },
              {
                title: "Introduction to your company",
                description: "The first step will be to get to know you and understand your company's needs."
              },
              {
                title: "A tailored demo for you",
                description: "It's time to discover our platform tailored to your organization's needs."
              }
            ].map((item, index) => (
              <div key={index} className="flex items-start space-x-3">
                <div className="rounded-full bg-custom-blue p-1 flex-shrink-0" style={{marginRight: '1rem'}}>
                  <Check className="h-4 w-4 text-white" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-custom-blue">{item.title}</h3>
                  <p className="text-gray-600">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div>
          <form name= "demo" className="space-y-4" netlify>
            <input
              type="text"
              className="w-full p-4 border border-gray-300 rounded-md"
              placeholder="Name"
            />
            <input
              type="text"
              className="w-full p-4 border border-gray-300 rounded-md"
              placeholder="Surname"
            />
            <input
              type="email"
              className="w-full p-4 border border-gray-300 rounded-md"
              placeholder="Business email address"
            />
            <input
              type="tel"
              className="w-full p-4 border border-gray-300 rounded-md"
              placeholder="Phone (include country code)"
            />
            <select className="w-full p-4 border border-gray-300 rounded-md bg-white appearance-none">
              <option value="">Which industry does your company belong to?</option>
              <option value="1">Tecnología</option>
              <option value="2">Finanzas</option>
              <option value="3">Salud</option>
              <option value="4">Educación</option>
              <option value="5">Otro</option>
            </select>
            <select className="w-full p-4 border border-gray-300 rounded-md bg-white appearance-none">
              <option value="">¿Qué tipo de solución estás buscando?</option>
              <option value="1">Gestión de datos</option>
              <option value="2">Análisis de mercado</option>
              <option value="3">Automatización de procesos</option>
              <option value="4">Inteligencia artificial</option>
              <option value="5">Otro</option>
            </select>
            <button
              type="submit"
              className="w-full p-4 bg-custom-blue text-white rounded-md hover:bg-custom-blue/90 transition-colors font-semibold hover:-translate-y-1 transition duration-400"
            >
              Request a Demo
            </button>
          </form>
          <div className="mt-6">
          </div>
        </div>
      </div>
    </div>
  )
}
