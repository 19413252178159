import React, { useState } from 'react';
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';

const Waitlist = () => {
  
  return (
    <StyledWrapper>
      <div>
        <form
          name="waitlist"
          method="post"
          action="/"
          
        >
          {/* Hidden input for Netlify form handling */}
          <input type="hidden" name="form-name" value="waitlist" />

            <div className="input-group">
              <input
                type="email"
                className="input"
                name="email"
                placeholder="Enter your company mail"
                autoComplete="off"
                required
              />
              <button
                className="button--submit"
                type="submit"
              >Join Waitlist</button>
            </div>
        </form>
    </div>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  .input-group {
  display: flex;
  align-items: center;
}

.input {
  min-height: 50px;
  max-width: auto;
  padding: 0 1rem;
  color: #757575;
  font-size: 15px;
  border: 1px solid #1b2f98;
  border-radius: 6px 0 0 6px;
  background-color: white;
}

.button--submit {
  min-height: 50px;
  padding: .5em 1em;
  border: none;
  border-radius: 0 6px 6px 0;
  background-color: #1b2f98;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  transition: background-color .3s ease-in-out;
}

.button--submit:hover {
  background-color: #5e5dcd;
}

.input:focus, .input:focus-visible {
  border-color: #3898EC;
  outline: none;
}

`;

export default Waitlist;
