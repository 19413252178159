import React, { useState } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { HoveredLink, Menu, MenuItem } from '../components/ui/navbar-menu';
import { cn } from 'src/lib/utils';
import logo from '../assets/images/logo_strathens2.svg';
import Button from '../components/socialbutton2';

export default function RootLayout() {
  return (
    <>
      <header className="relative w-full flex items-center justify-center">
        <Navbar />
      </header>
      <main>
        <Outlet />
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
}
function Navbar({ className }) {
  const [active, setActive] = useState(null);
  return (
    <div
      className={cn(
        'fixed top-0 md:top-2 inset-x-0 max-w-2xl mx-auto z-50',
        className
      )}
    >
      <Menu setActive={setActive}>
        <MenuItem
          setActive={setActive}
          active={active}
          item={
            <Link to="/">
              <img
                src={logo}
                alt="Logo"
                className="inline-block h-8 w-auto align-middle mr-6"
              />
            </Link>
          }
        />
        <MenuItem setActive={setActive} active={active} item="Platform">
          <div className="flex flex-col space-y-4 text-sm">
            <HoveredLink to="/Platform">Features</HoveredLink>
          </div>
        </MenuItem>
        {/*<MenuItem setActive={setActive} active={active} item="Platform">
          <div className="  text-sm grid grid-cols-2 gap-10 p-4">
            <ProductItem
              title="Planning"
              to="/planning"
              src="https://assets.aceternity.com/demos/tailwindmasterkit.webp"
              description="Prepare for tech interviews like never before."
            />
            <ProductItem
              title="Analytics"
              to="/analisis"
              src="https://assets.aceternity.com/demos/tailwindmasterkit.webp"
              description="Production ready Tailwind css components for your next project"
            />
          </div>
        </MenuItem>*/}
        <MenuItem setActive={setActive} active={active} item="Contact us">
          <div className="flex flex-col space-y-4 text-sm">
            {/*<HoveredLink to="/aboutus">About us</HoveredLink>*/}
            <HoveredLink to="/contact">Contact us</HoveredLink>
          </div>
        </MenuItem>
        {/*<MenuItem setActive={setActive} active={active} item="Pricing">
          <div className="flex flex-col space-y-4 text-sm">
            <HoveredLink href="/hobby">Hobby</HoveredLink>
            <HoveredLink href="/individual">Individual</HoveredLink>
            <HoveredLink href="/team">Team</HoveredLink>
            <HoveredLink href="/enterprise">Enterprise</HoveredLink>
          </div>
        </MenuItem>*/}
      </Menu>
    </div>
  );
}

export function Footer({ className }) {
  return (
    <>
      <div
        className={cn(
          'mx-auto max-w-7xl px-6 py-12 grid grid-cols-1 md:grid-cols-2 lg:px-8',
          className
        )}
      >
        {/* Left side: Logo and Rights Reserved text */}

        <div className="w-40 my-8 md:my-0 mx-auto md:mx-0 md:w-40">
          <img src={logo} alt="logo" className="object-contain w-full h-full" />
        </div>

        {/* Right side: Social Buttons */}
        <Button />

        <div className="grid grid-cols-1 w-full md:mt-0 mt-8">
          <p className="md:text-left text-center text-xs leading-5 text-gray-500">
            &copy; 2024 Strathens. All rights reserved. Icons by
            <Link
              className="md:text-left text-center text-xs leading-5 text-gray-500"
              to="https://icons8.com/"
            >
              Icons8
            </Link>
          </p>
          <Link
            className="md:text-left text-center text-xs leading-5 text-gray-500"
            to="/privacy-policy"
          >
            Política de Privacidad
          </Link>
        </div>
      </div>
    </>
  );
}
