import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div>
            <h1>Política de Privacidad</h1>
            <p>
                En [strathens], nos comprometemos a proteger tu privacidad. Esta política de privacidad
                describe cómo recopilamos, utilizamos y protegemos la información que proporcionas cuando visitas nuestro sitio web.
            </p>
            <h2>1. Información que recopilamos</h2>
            <p>
                Podemos recopilar la siguiente información:
                <ul>
                    <li>Nombre y datos de contacto, como dirección de correo electrónico</li>
                    <li>Información demográfica, como preferencias e intereses</li>
                    <li>Otra información relevante para encuestas y/o ofertas</li>
                </ul>
            </p>
            <h2>2. Cómo utilizamos la información</h2>
            <p>
                Utilizamos la información para entender tus necesidades y proporcionarte un mejor servicio,
                en particular por las siguientes razones:
                <ul>
                    <li>Mantenimiento de registros internos</li>
                    <li>Mejorar nuestros productos y servicios</li>
                    <li>Enviar correos promocionales sobre nuevos productos, ofertas especiales u otra información</li>
                </ul>
            </p>
            <h2>3. Seguridad</h2>
            <p>
                Estamos comprometidos a garantizar que tu información esté segura. Para evitar el acceso no autorizado
                o divulgación, hemos implementado procedimientos físicos, electrónicos y administrativos adecuados
                para proteger y asegurar la información que recopilamos en línea.
            </p>
            <h2>4. Enlaces a otros sitios web</h2>
            <p>
                Nuestro sitio web puede contener enlaces a otros sitios de interés. Sin embargo, una vez que hayas utilizado estos enlaces
                para salir de nuestro sitio, debes tener en cuenta que no tenemos control sobre ese otro sitio web.
            </p>
            <h2>5. Control de tu información personal</h2>
            <p>
                Puedes restringir la recopilación o el uso de tu información personal en cualquier momento poniéndote en contacto con nosotros.
            </p>
            <p>
                Si tienes alguna pregunta sobre nuestra política de privacidad, no dudes en contactarnos en [email de contacto].
            </p>
        </div>
    );
};

export default PrivacyPolicy;
